.alert-icon {
  padding: 2px;
  height: 28px;
}
.alert-icon .svg-inline--fa {
  max-height: 100%;
  width: auto;
  height: 28px;
}
.alert {
  border-radius: 6px;
  @apply border;
  @apply border-solid;
}
.alert.success {
  @apply bg-green-50;
  @apply text-green-900;
  @apply border-green-900;
}

.dark .alert.success {
  @apply bg-emerald-700/30;
  @apply text-green-100;
  @apply border-emerald-700/30;
}

.alert.warning {
  @apply bg-amber-50;
  @apply text-amber-800;
  @apply border-amber-800;
}

.dark .alert.warning {
  @apply bg-amber-700/30;
  @apply text-amber-100;
  @apply border-amber-700/30;
}

.alert.error {
  @apply bg-red-50;
  @apply text-red-800;
  @apply border-red-800;
}

.dark .alert.error {
  @apply bg-red-700/30;
  @apply text-red-100;
  @apply border-red-700/30;
}

