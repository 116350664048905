@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  font-size: 95%;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-inforcer-deep-blue {
  background-color: #102550;
}

.bg-inforcer-dark-grey {
  background-color: #1c1c1c4f;
}

.tenant-name-highlight {
  font-weight: bold;
  color: #007bff; /* Example styling */
}

span.recharts-legend-item-text {
  @apply text-gray-500 !important;
}

.loading-spinner {
  border: 6px solid;
  @apply border-gray-900/15 border-r-inforcer-cyan;
  animation: loading-animation 900ms linear infinite;
}

.dark .loading-spinner {
  @apply border-slate-700 border-r-dark-mode-primary;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4caf50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Custom dark mode overrides */


/* List table */
.dark #root .App .container .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 {
  @apply bg-slate-900 text-gray-200/10;
}
.dark #root .App .MuiTableRow-root {
  @apply bg-slate-900 text-gray-200 !important;
}
.dark #root .App .Mui-TableHeadCell-Content-Wrapper, .dark #root .App td.MuiTableCell-root.MuiTableCell-body {
  @apply bg-slate-900 text-gray-200 !important;
}

.dark .MuiTableRow-root.MuiTableRow-head.css-1o2kpv2-MuiTableRow-root {
  @apply bg-slate-900 text-gray-200;
}

/* Modify menu */

.dark .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  @apply bg-slate-900 text-gray-200;
}


/* table filter icons */
.dark #root .App .Mui-TableHeadCell-Content-Labels.MuiBox-root path, .dark #root .App .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active path {
  @apply fill-gray-200;
}

.dark #root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  @apply fill-gray-200;
}

/* Select all checkbox */
.dark #root .App .Mui-TableHeadCell-Content-Labels .Mui-checked svg path, .dark #root .App .Mui-TableHeadCell-Content-Labels .MuiCheckbox-indeterminate svg path {
  @apply fill-dark-mode-primary;
}

/* Individual checkboxes */
.dark #root .Mui-selected svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium path {
  @apply fill-dark-mode-primary;
}

.dark #root .App .Mui-TableHeadCell-Content-Labels svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  @apply fill-gray-200;
}


.dark #root .App .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd { 
  @apply text-gray-200 border-gray-200;
}

.dark #root .App .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  border: 1px solid white;
}

/* Bottom Box */

.dark #root .App .MuiBox-root, .dark #root .App label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root, .dark #root .App .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input {
  @apply bg-slate-900 text-gray-200;
}

/* Charts */

.dark #root .App .recharts-wrapper text {
  @apply fill-gray-300;
}

.dark #root .App .recharts-wrapper line {
  @apply stroke-gray-300;
}

.dark #root .App .recharts-wrapper span.recharts-legend-item-text {
  @apply text-gray-300 !important;
}

.dark #root .App .recharts-tooltip-label{
  @apply text-inforcer-navy !important;
}
